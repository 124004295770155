<template>
  <div class="login_wrap">
    <div class="top">
      <div class="logo" @click="$router.push('/index/home')">
        <img :src="logo" alt="" />
      </div>
      <!-- <div>
        <img style="width: 20px; height: 20px; margin-right: 10px" src="@/assets/img/login/cal.png" alt="" />
        <span>客服电话：{{ info.site_fwdh }}</span>
      </div> -->
    </div>
    <div class="center-img-box" @click.stop="click(1)" :style="{ backgroundImage: `url(${imgUrl})` }">
      <div class="login_box" @click.stop="errclick(1)">
        <div class="login_top">
          <div class="login_title" v-if="type == 101">用户登录</div>
          <div class="login_title" v-if="type == 102">找回密码</div>
          <div class="login_title" v-if="type == 103">扫码登录智联科创</div>
        </div>
        <div class="code_wrap" v-if="type == 101" style="justify-content: flex-start">
          <div class="input_wrap" style="margin-top: 40px">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/user.png" />
            <input placeholder="请输入用户名" v-model="username" v-on:keyup.enter="handleLogin" />
          </div>
          <div class="input_wrap">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/suo.png" />
            <input placeholder="请输入密码" type="password" v-model="password" v-on:keyup.enter="handleLogin" />
          </div>
          <div class="btn" @click="handleLogin">立即登录</div>
          <div class="forget">忘记密码？<span class="c_p" @click="type = 102">找回密码</span></div>
        </div>
        <div class="code_wrap code_wrap2" v-if="type == 102" style="justify-content: flex-start">
          <div class="input_wrap" style="margin-top: 20px">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/user.png" />
            <input placeholder="请输入用户名" autocomplete="new-password" v-model="p.username" />
          </div>
          <div class="code-box">
            <div class="inp-box"><i class="el-icon-mobile"></i> <input placeholder="请输入手机号" autocomplete="new-password" v-model="p.phone" /></div>
            <el-button :disabled="disabled" type="primary" @click="getCode">验证码</el-button>
            <!-- <span class="c_p" @click="getCode">验证码</span> -->
          </div>
          <div class="input_wrap">
            <i class="el-icon-key"></i>
            <input placeholder="请输入验证码" autocomplete="new-password" v-model="p.vcode" />
          </div>
          <div class="input_wrap">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/suo.png" />
            <input placeholder="请输入新密码" autocomplete="new-password" type="password" v-model="p.pwd" />
          </div>
          <div class="btn" @click="findPass">找回密码</div>
        </div>
        <div class="code_wrap" v-if="type == 103">
          <div class="code"></div>
          <p>打开手机 <span>智联科创APP</span> 扫描二维码</p>
        </div>
        <div v-if="type == 101" class="login_bottom flex-end">
          <div class="right" @click="handleRegister">
            <span>立即注册</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div v-if="type == 102" class="login_bottom flex-b">
          <div class="right" @click="type = 101">
            <i class="el-icon-arrow-left"></i>
            <span>去登录</span>
          </div>
          <div class="right" @click="handleRegister">
            <span>立即注册</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_inner">
        <div class="info_item">
          <div v-for="item in arr" :key="item.id">
            <span>{{ item.title }}：</span>
            <span class="cont" @click="click(item)">{{ item.zjhm }}</span>
          </div>
        </div>
        <p>{{ bottom.str1 }}</p>
        <div style="display: flex; justify-content: space-between">
          <p>{{ bottom.str2 }}</p>
          <p>公司地址：{{ bottom.address }}</p>
        </div>
        <div style="display: flex; justify-content: space-between">
          <p>{{ bottom.str3 }}</p>
          <div v-html="bottom.support"></div>
          <!-- <p>
            {{ bottom.support }}
          </p> -->
        </div>
      </div>
    </div>
    <div class="popup" v-if="dialogVisible && imgarr.image" @click="endDialog">
      <div class="po">
        <el-button icon="el-icon-close" circle @click="endDialog"></el-button>
        <el-image :src="imgarr.image" @load="loadImage" @click="handleJumps"> </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import { LsetItem } from "@/utils/storage";
import { handleJump, loadImage } from "@/utils";
import FingerprintJS from "fingerprintjs2";
export default {
  data() {
    return {
      type: 101, //101用户登录  102找回密码  103扫码
      username: "", //账号
      password: "", //密码
      imgUrl: "",
      logo: "",
      info: {},
      handleJump,
      loadImage,
      dialogVisible: false,
      imgarr: {},
      bottom: {},
      disabled: false,
      p: {
        username: "", //用户登录名
        phone: "", //手机号
        vcode: "", //验证码
        pwd: "", //新密码
      },
      loot: {},
      arr: [],
    };
  },
  created() {
    this.$api("zone.toolsBanner", { type: 1 }).then((res) => {
      // console.log(res);
      this.imgUrl = res.data.logo;
    });
    // this.$api("home.getNavInfo").then((res) => {
    //   this.info = res.data;
    //   this.logo = res.data.setting.logo;
    //   this.arr = res.data.license;
    // });
    this.$api("home.getComHeader").then((res) => {
      this.info = res.data;
      this.logo = res.data.setting.logo;
      // this.arr = res.data.license;
      localStorage.setItem("small_logo", res.data.setting.logo);
    });
    this.$api("home.getComBottom").then((res) => {
      this.arr = res.data.license;
      this.bottom = res.data.bottom;
    });
  },
  methods: {
    // 设备唯一标识
    getFingerprint() {
      FingerprintJS.get(function (components) {
        const values = components.map(function (component, index) {
          if (index === 0) {
            //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, "");
          }
          return component.value;
        });
        // 生成最终id murmur
        const murmur = FingerprintJS.x64hash128(values.join(""), 31);
        // console.log("设备标识----", murmur);
        return murmur;
      });
    },
    click(item) {
      let url = "";
      this.imgarr = {};
      if (item.image) {
        console.log(123);
        this.imgarr = item;
        this.dialogVisible = true;
      } else if (item.url) {
        url = item.url;
        window.open(url, "_blank");
      }
    },
    // toLoginPge() {
    //   this.type = 101;
    // },

    endDialog() {
      this.imgarr = {};
      this.dialogVisible = false;
    },
    handleJumps() {
      if (this.imgarr.url) {
        window.open(this.imgarr.url, "_blank");
      }

      this.imgarr = {};
      this.dialogVisible = false;
    },

    errclick() {
      // 阻止事件
      // console.log(456123);
    },
    handleLogin() {
      if (!this.username) return;
      if (!this.password) return;
      // let device_id = this.getFingerprint();
      FingerprintJS.get((components) => {
        const values = components.map((component, index) => {
          if (index === 0) {
            //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, "");
          }
          return component.value;
        });
        // 生成最终id murmur
        const device_id = FingerprintJS.x64hash128(values.join(""), 31);
        // console.log("设备标识----", device_id);
        this.$api("login.getLogin", {
          login_type: "101", //账号密码登录
          username: this.username,
          pass: this.pass,
          password: md5(this.password), //密码MD5加密
          device_id: device_id,
        }).then((res) => {
          LsetItem("token", res.data.token);
          this.$api("account.getInfo").then((re) => {
            if (re.code == 200) {
              LsetItem("info", re.data);
              localStorage.setItem("user_info", JSON.stringify(re.data));
              this.$store.dispatch("getBadge");
              this.$router.push("/");
            }
          });
        });
      });
    },
    getCode() {
      if (!this.p.phone) return;
      this.$api("login.getCode", {
        type: "2", //103找回密码
        phone: this.p.phone,
      }).then((res) => {
        if (res.code == 200) {
          this.disabled = true;
          this.$message.success("验证码已发送");
          setTimeout(() => {
            this.disabled = false;
          }, 4000);
        }
      });
    },
    findPass() {
      if (!this.p.username || !this.p.phone || !this.p.vcode || !this.p.pwd) return;

      this.$api("login.findPass", {
        username: this.p.username,
        phone: this.p.phone,
        vcode: this.p.vcode,
        password: md5(this.p.pwd),
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("已重置密码，请重新登录");
          setTimeout(() => {
            this.type = 101;
          }, 1000);
        }
      });
    },
    handleRegister() {
      this.$router.push("/register");
    },
    change() {
      this.type = this.type == 101 ? 103 : 101;
    },
    wxLogin() {
      console.log(666);
    },
  },
};
</script>

<style lang="less" scoped>
.pointer {
  cursor: pointer;
}
.login_wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .top {
    // width: 100%;
    width: 1200px;
    margin: 0 auto;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 38px 0 22px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    .logo {
      width: 243px;
      height: 67px;
    }
  }
  .center-img-box {
    flex: 1;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: aquamarine;
    .login_box {
      position: absolute;
      top: 80px;
      right: 158px;
      width: 400px;
      height: 463px;
      background: #ffffff;
      box-shadow: 0px 0px 40px 0px rgba(226, 238, 255, 0.5);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      .login_top {
        padding: 0 30px;
        position: relative;
        .login_title {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          height: 100px;
          line-height: 140px;
          border-bottom: 1px solid #eeeeee;
        }
        .img_box {
          position: absolute;
          right: 5px;
          top: 5px;
          display: flex;
          cursor: pointer;
        }
        .sanjiao {
          margin-top: 10px;
          width: 124px;
          height: 25px;
          position: relative;
          img {
            position: absolute;
          }
          span {
            position: absolute;
            top: 3px;
            left: 6px;
            font-size: 14px;
            font-weight: 400;
            color: @priceRed;
          }
        }
        .sanjiao1 {
          width: 147px;
        }
        .img {
          width: 71px;
          height: 71px;
        }
      }
      .code_wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .code {
          width: 145px;
          height: 142px;
          background-color: #eee;
        }
        p {
          margin-top: 40px;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          span {
            color: @themeColor;
          }
        }
        .input_wrap {
          width: 310px;
          height: 40px;
          background: #f8f8f8;
          border: 1px solid #ededed;
          border-radius: 20px;
          padding: 10px 20px;
          box-sizing: border-box;
          display: flex;
          margin-bottom: 25px;
          input {
            border: none;
            background: #f8f8f8;
            flex: 1;
            margin-left: 12px;
            outline: none;
          }
          .el-icon-mobile,
          .el-icon-key {
            font-size: 20px;
            color: @themeColor;
          }
        }
        .code-box {
          width: 310px;
          height: 40px;
          // background: #f8f8f8;
          // border: 1px solid #ededed;
          border-radius: 20px;
          // padding: 10px 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;
          .inp-box {
            width: 230px;
            height: 40px;
            background: #f8f8f8;
            border: 1px solid #ededed;
            border-radius: 20px;
            padding: 10px 20px;
            display: flex;
            box-sizing: border-box;
            input {
              border: none;
              background: #f8f8f8;
              flex: 1;
              margin-left: 12px;
              outline: none;
            }
            .el-icon-mobile,
            .el-icon-key {
              font-size: 20px;
              color: @themeColor;
            }
          }
        }
        .btn {
          width: 310px;
          height: 40px;
          background: @themeColor;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .forget {
          width: 310px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            color: @themeColor;
          }
        }
      }
      .code_wrap2 {
        .input_wrap {
          margin-bottom: 18px;
        }
      }

      .login_bottom {
        width: 400px;
        height: 60px;
        background: #f7f7f7;
        border-radius: 0 0 12px 12px;
        padding: 0 30px;
        box-sizing: border-box;
        display: flex;

        .left {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          .login_weixin {
            width: 22px;
            height: 22px;
            margin-right: 9px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          color: @themeColor;
          line-height: 1;
          cursor: pointer;
          span {
            margin-right: 6px;
          }
        }
      }
      .flex-end {
        // justify-content: space-between;
        justify-content: flex-end;
      }
      .flex-b {
        justify-content: space-between;
        // justify-content: flex-end;
      }
    }
  }
  .bottom {
    min-height: 150px;
    background: #2c2f3a;
    padding-top: 39px;
    padding-bottom: 20px;
    box-sizing: border-box;
    color: #fffefe;
    font-size: 13px;
    line-height: 30px;
    .bottom_inner {
      width: 1200px;
      margin: 0 auto;
      .info_item {
        display: flex;
        flex-wrap: wrap;
        .cont {
          margin-right: 15px;
          padding-right: 15px;
          color: #b2b4c4;
          border-right: 1px solid #fffefe;
          cursor: pointer;
        }
      }
    }
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .po {
      position: relative;
      .el-button {
        position: absolute;
        right: -50px;
        top: -50px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
      }
    }
  }
}
</style>
